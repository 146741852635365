import { useParams } from 'react-router-dom';

import { DrawerLayout } from '@/components/common/drawer/drawer-layout';
import { useParamsRequired } from '@/lib/navigation';

import { CartList } from './components/cart-list';
import { FocusCartEntry } from './components/focus-cart-entry';

export function CartPage() {
  const { restaurantId } = useParamsRequired('restaurantId');
  const { cartEntryId } = useParams();

  return (
    <DrawerLayout
      drawer={
        cartEntryId && (
          <FocusCartEntry
            restaurantId={restaurantId}
            cartEntryId={cartEntryId}
          />
        )
      }
    >
      <CartList restaurantId={restaurantId} />
    </DrawerLayout>
  );
}
