import { InMemoryCache } from '@apollo/client/cache';
import { ApolloClient } from '@apollo/client/core/ApolloClient';
import { setContext } from '@apollo/client/link/context';
import { from } from '@apollo/client/link/core/from';
import { createHttpLink } from '@apollo/client/link/http/createHttpLink';
import i18next from 'i18next';

import { auth, webViewAuth } from '../auth';

const localeLink = setContext(async (_, { headers }) => ({
  headers: {
    ...headers,
    'Accept-Language': i18next.language,
  },
}));

const httpLink = createHttpLink({
  uri: import.meta.env.VITE_PUBLIC_API_URL,
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

async function getAuthToken() {
  const isAuthenticated = await auth.isAuthenticated();
  const token = isAuthenticated ? await auth.getTokenSilently() : null;
  const webViewToken = webViewAuth.getToken();
  return token || webViewToken;
}

export const apolloClient = new ApolloClient({
  link: from([localeLink, authLink, httpLink]),
  cache: new InMemoryCache({
    // fragments: createFragmentRegistry(FullCartFragment),
  }),
});
