import { ComponentProps } from 'react';

import { cn } from '@/lib/utils';

export function RoundButtonFilled(props: ComponentProps<'button'>) {
  return (
    <button
      {...props}
      className={cn(
        'flex h-9 w-9 items-center justify-center rounded-full bg-black p-0.5 text-white transition-opacity duration-300 active:animate-press-in',
        props.className,
      )}
    />
  );
}

export function RoundButtonOutline(props: ComponentProps<'button'>) {
  return (
    <button
      {...props}
      className={cn(
        'flex h-9 w-9 items-center justify-center rounded-full border border-black p-0.5 transition-opacity duration-300 active:animate-press-in',
        props.className,
      )}
    />
  );
}
