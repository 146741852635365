import { RadioGroupItem } from '@/components/ui/radio-group';
import { SupportedPaymentType } from '@/lib/__generated__/graphql';
import { PaymentConfig } from '@/types/types';

export function PaymentSelectItem(props: {
  paymentConfig: PaymentConfig;
  setIsPaymentDrawerOpen(value: boolean): void;
  setSelectedPaymentType(paymentType: SupportedPaymentType): void;
}) {
  return (
    <button
      className="flex items-center justify-between py-4"
      onClick={() => {
        props.setSelectedPaymentType(props.paymentConfig.id);
        props.setIsPaymentDrawerOpen(false);
      }}
    >
      <div className="flex flex-row items-center gap-5">
        <img
          src={props.paymentConfig.imageUrl}
          alt={props.paymentConfig.name}
          className="h-6"
        />
        <p className="text-md">{props.paymentConfig.name}</p>
      </div>

      <RadioGroupItem
        value={props.paymentConfig.id}
        id={props.paymentConfig.id}
      />
    </button>
  );
}
