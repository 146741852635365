import { graphql } from '@/lib/__generated__';
import {
  EatAdmin_Order_GetOrder_Input,
  Eat_Order_TableFragment,
} from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function getOrder(
  input: EatAdmin_Order_GetOrder_Input,
): Promise<Eat_Order_TableFragment> {
  const { data } = await apolloClient.query({
    query: graphql(`
      query Eat_Order_GetOrder($input: Eat_Order_GetOrder_Input!) {
        Eat_Order_GetOrder(input: $input) {
          order {
            __typename
            ...Eat_Order_Table
          }
        }
      }
    `),
    variables: {
      input,
    },
    fetchPolicy: 'no-cache',
  });

  const order = data.Eat_Order_GetOrder.order;

  if (!order) {
    throw new Error('Order not found');
  }

  if (order.__typename !== 'Eat_Order_Table') {
    throw new Error('Order is not a table order');
  }

  return order;
}
