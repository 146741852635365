import { graphql } from '@/lib/__generated__';
import { apolloClient } from '@/lib/apollo/apollo-client';
import { getAnonId } from '@/lib/utils';

export async function getRestaurantWithMenuAndCart({
  restaurantId,
}: {
  restaurantId: string;
}) {
  const { data } = await apolloClient.query({
    query: graphql(`
      query Eat_LoadRestaurantWithMenuAndCart($restaurantId: ID!, $anonId: ID) {
        Eat_Restaurant_GetRestaurant(input: { restaurantId: $restaurantId }) {
          restaurant {
            ...Eat_Restaurant
          }
        }

        Eat_Menu_GetMenuForRestaurant(input: { restaurantId: $restaurantId }) {
          menu {
            ...Eat_Menu
          }
        }

        Eat_Cart_GetCartForRestaurant(
          anonId: $anonId
          input: { restaurantId: $restaurantId }
        ) {
          cart {
            ...Eat_Cart
          }
        }
      }
    `),
    variables: {
      restaurantId,
      anonId: getAnonId(),
    },
  });

  return {
    restaurant: data.Eat_Restaurant_GetRestaurant.restaurant,
    menu: data.Eat_Menu_GetMenuForRestaurant.menu,
    cart: data.Eat_Cart_GetCartForRestaurant.cart,
  };
}
