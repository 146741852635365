import React, { ReactNode } from 'react';
import Markdown from 'react-markdown';

import { Link } from '@/lib/navigation';
import { cn } from '@/lib/utils';

type Props = {
  name: ReactNode;
  description?: string | null;
  priceText?: ReactNode;
  imageUrl?: string | null;
  highlighted?: boolean;
  onImageClick?(event: React.MouseEvent): void;
  imageClassName?: string;
  imageLinkHref?: string;
  imageIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
};

export function ListEntry({
  name,
  description,
  imageUrl,
  priceText,
  highlighted,
  onImageClick,
  imageLinkHref,
  imageIcon,
  imageClassName,
  children,
  className,
}: Props) {
  return (
    <div className={cn('my-5 flex w-full gap-4', className)}>
      <ImageWrapper
        imageLinkHref={imageLinkHref}
        onClick={onImageClick}
        className={cn(
          'relative h-[5.75rem] w-[5.75rem] rounded-md',
          imageUrl == null && 'bg-muted',
        )}
      >
        {imageUrl && (
          <img
            loading="lazy"
            src={imageUrl ?? ''}
            alt={typeof name === 'string' ? name : ''}
            className={cn(
              'block h-full w-full rounded-sm transition-all duration-200',
              highlighted && 'shadow-[0_0_0_3px] shadow-laaxred',
              onImageClick && 'cursor-pointer',
              highlighted && 'animate-press-in',
              imageClassName,
            )}
          />
        )}
        {imageIcon && (
          <div className="absolute bottom-0 right-0 flex h-7 w-7 translate-x-1.5 translate-y-1/4 items-center justify-center rounded-full border border-border bg-background text-xs">
            {imageIcon}
          </div>
        )}
      </ImageWrapper>
      <div className="flex flex-1 flex-col items-stretch">
        <div className="flex flex-1 flex-row gap-3">
          <div className="flex-1">
            <h4 className="mb-1 leading-5">{name}</h4>
            {description && (
              <Markdown className="text-sm leading-5 text-muted-foreground">
                {description}
              </Markdown>
            )}
          </div>
          <div className="flex h-full flex-col items-end justify-end">
            <div className="flex-1">{children}</div>
          </div>
        </div>
        <div className="text-right text-sm leading-3">{priceText}</div>
      </div>
    </div>
  );
}

const ImageWrapper = ({
  imageLinkHref,
  ...props
}: {
  imageLinkHref?: string;
  children: ReactNode;
  className: string;
  onClick?(event: React.MouseEvent): void;
}) =>
  imageLinkHref ? (
    <Link to={imageLinkHref} preventScrollReset {...props} />
  ) : (
    <div {...props} />
  );
