import { useMainStore } from '@/stores/main-store';

import { FocusCartDeal } from './focus-cart-deal';
import { FocusCartItem } from './focus-cart-item';

type Props = {
  restaurantId: string;
  cartEntryId: string;
};

export function FocusCartEntry(props: Props) {
  const { cart } = useMainStore((state) => ({
    cart: state.cart,
  }));

  const cartEntry = cart?.entries.find(
    (cartEntry) => cartEntry.id === props.cartEntryId,
  );

  if (!cartEntry) {
    return null;
  }

  switch (cartEntry.__typename) {
    case 'Eat_Cart_Item':
      return (
        <FocusCartItem cartItem={cartEntry} restaurantId={props.restaurantId} />
      );

    case 'Eat_Cart_Deal':
      return (
        <FocusCartDeal cartDeal={cartEntry} restaurantId={props.restaurantId} />
      );

    default:
      return null;
  }
}
