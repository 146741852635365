import { Auth0Client } from '@auth0/auth0-spa-js';

export const auth = window.isSecureContext
  ? new Auth0Client({
      domain: import.meta.env.VITE_PUBLIC_AUTH0_DOMAIN,
      clientId: import.meta.env.VITE_PUBLIC_AUTH0_CLIENT_ID,
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    })
  : //Auth0 client can only be instantiated in secure context because of web crypto.
    //On urls seperate from hosting env or localhost, login won't anyway work because of auth-config.
    //We might as well mock the thing instead of forcing ssl dev servers.
    (new Proxy(
      {
        loginWithRedirect() {
          alert('Auth is disabled outside of https:// or localhost urls!');
          return Promise.resolve();
        },
      } as Record<any, any>,
      {
        get(target, property) {
          if (!(property in target)) {
            return () => Promise.resolve(false);
          }
          //@ts-expect-error
          return target[property];
        },
      },
    ) as Auth0Client);

//Fire and forget checkSession to make auth0 refresh current status.
auth.checkSession();

/**
 * In case of webview, we use the existing app token which we will inject into the webview.
 */
export const webViewAuth = {
  getToken() {
    return window.accessToken;
  },
};

declare global {
  interface Window {
    accessToken: string;
  }
}
