import { useTranslation } from '@/i18n/client';
import { cn, rappenToFrancs } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

import { ContentSlider } from './content-slider';

type Props = {
  buttonText: string;
  onButtonClick(): void;
  buttonDisabled?: boolean;
  stepNumber: number;
};

export function OrderFooter(props: Props) {
  const { cart } = useMainStore();
  const { t } = useTranslation();
  if (cart == null) {
    return null;
  }

  const quantityInCart = cart.entries.reduce(
    (acc, curr) => acc + curr.quantity,
    0,
  );

  return (
    <>
      {/* Padder in static flow with sort of matched height */}
      <div className="h-20" />
      <div className="fixed bottom-0 left-0 right-0 z-10 flex items-center justify-between border-t bg-background px-5 py-2">
        <div className="flex w-full flex-col justify-between gap-2">
          <div className="mt-0.5 flex items-center justify-between">
            <button
              className={cn(
                'flex items-center gap-2 rounded-md bg-laaxred py-2.5 pl-6 pr-8 text-white disabled:bg-laaxredlight',
              )}
              onClick={props.onButtonClick}
              disabled={props.buttonDisabled}
            >
              <div className="relative">
                <img
                  src="/icons/cart.svg"
                  className="h-4 w-4 object-contain"
                  alt=""
                  style={{ filter: 'invert(1)' }}
                />
                <div
                  className={cn(
                    'text-s absolute -right-3.5 -top-[1.3rem] flex h-[1.33rem] w-[1.33rem] items-center justify-center rounded-full border border-laaxred bg-primary-foreground pt-[0.12rem] text-sm leading-4 text-laaxred',
                    props.buttonDisabled &&
                      'border-laaxredlight text-laaxredlight',
                  )}
                >
                  <ContentSlider
                    axis="vertical"
                    contentIndex={quantityInCart}
                    duration={200}
                  >
                    {quantityInCart}
                  </ContentSlider>
                </div>
              </div>
              <p>{props.buttonText}</p>
            </button>

            <p className="mt-1 text-xl">
              {rappenToFrancs(cart.entriesTotalRappen)} CHF
            </p>
          </div>

          <p className="text-xs text-muted-foreground">
            {t('Cart summary - Step number', 'Step {{stepNumber}} of 4', {
              stepNumber: props.stepNumber,
            })}
          </p>
        </div>
      </div>
    </>
  );
}
