import { MapPin } from 'lucide-react';

import { TableIcon } from '@/components/common/icons/table-icon';
import { useTranslation } from '@/i18n/client';
import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';

import { ViewMenuButton } from './view-menu-button';

export function Welcome(props: {
  restaurant: Eat_RestaurantFragment;
  tableNumber: string | null;
}) {
  const { t } = useTranslation();

  return (
    <div className="flex h-full flex-col justify-between">
      <div className="flex flex-col items-center">
        <p className="mb-5 text-3xl font-semibold">{props.restaurant.name}</p>

        <div className="flex flex-col items-center gap-1 text-sm">
          <div className="flex items-center gap-1 text-muted-foreground">
            {/* FIXME: This should be dynamic */}
            <MapPin className="size-4" />
            <p>Via Nova 80, 7017 Flims Dorf</p>
          </div>

          <div className="flex items-center gap-1">
            {props.restaurant.orderingEnabled ? (
              <p className="text-green-500">{t('OPEN', 'OPEN')}</p>
            ) : (
              <p className="text-red-500">{t('CLOSED', 'CLOSED')}</p>
            )}
          </div>
        </div>
      </div>

      {/* FIXME: This should be dynamic, depending on the restaurant ordering mode */}
      <div className="flex flex-col items-center gap-1 text-sm">
        <p className="text-muted-foreground">
          {t(
            'Home - Bring to your table',
            'We will gladly bring your order to your table',
          )}
        </p>
        <div className="flex items-center gap-1">
          <TableIcon />
          <p>
            {t('Home - Table', 'Table')} {props.tableNumber}
          </p>
        </div>
      </div>

      <div className="flex flex-col items-center gap-3">
        <ViewMenuButton
          restaurant={props.restaurant}
          tableNumber={props.tableNumber}
        />

        {/* FIXME: This won’t make sense within the app */}
        <p className="text-xs text-muted-foreground">
          {t('Home - Order via LAAX App 1', 'You can also order via the')}{' '}
          <a
            href="https://laax.com/laax-app"
            className="text-blue-500 underline"
          >
            {t('Home - Order via LAAX App 2', 'LAAX App')}
          </a>
        </p>
      </div>
    </div>
  );
}
