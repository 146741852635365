import { graphql } from '@/lib/__generated__';
import {
  Eat_CartFragment,
  Eat_Cart_GetCartForRestaurant_Input,
} from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';
import { getAnonId } from '@/lib/utils';

export async function getCart(
  input: Eat_Cart_GetCartForRestaurant_Input,
): Promise<Eat_CartFragment> {
  const { data } = await apolloClient.query({
    query: graphql(`
      query Eat_Cart_GetCartForRestaurant(
        $input: Eat_Cart_GetCartForRestaurant_Input!
        $anonId: ID
      ) {
        Eat_Cart_GetCartForRestaurant(input: $input, anonId: $anonId) {
          cart {
            ...Eat_Cart
          }
        }
      }
    `),
    variables: {
      input,
      anonId: getAnonId(),
    },
    fetchPolicy: 'no-cache',
  });

  return data.Eat_Cart_GetCartForRestaurant.cart;
}
