import { graphql } from '@/lib/__generated__';
import { Eat_Order_TableFragment } from '@/lib/__generated__/graphql';
import { apolloClient } from '@/lib/apollo/apollo-client';

export async function listOrdersToday(
  anonId: string,
): Promise<Eat_Order_TableFragment[]> {
  const response = await apolloClient.query({
    query: graphql(`
      query Eat_Order_ListOrdersToday($anonId: ID) {
        Eat_Order_ListOrdersToday(anonId: $anonId) {
          orders {
            __typename
            ...Eat_Order_Table
          }
        }
      }
    `),
    variables: {
      anonId,
    },
  });

  return response.data.Eat_Order_ListOrdersToday.orders.filter(
    (order) => order.__typename === 'Eat_Order_Table',
  );
}
