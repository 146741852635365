import { useTranslation } from '@/i18n/client';
import { Eat_RestaurantFragment } from '@/lib/__generated__/graphql';

import { InstructionSteps } from './instruction-steps';

type Props = {
  restaurant: Eat_RestaurantFragment;
};

export function MenuHeader(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="mt-5">
      <div className="flex items-center justify-between">
        <div className="text-md">
          {props.restaurant.orderingEnabled ? (
            <span className="text-green-500">{t('OPEN', 'OPEN')}</span>
          ) : (
            <span className="text-red-500">{t('CLOSED', 'CLOSED')}</span>
          )}{' '}
          •{' '}
          {
            // FIXME: This should not be hard-coded!
            'closes at 16:15'
          }
        </div>

        <InstructionSteps />
      </div>
    </div>
  );
}
