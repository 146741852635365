import { useEffect, useState } from 'react';

export function useDelayedValue<TValue>(value: TValue, delay: number) {
  const [prev, setPrev] = useState(value);
  useEffect(() => {
    const timeout = setTimeout(() => setPrev(value), delay);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay]);
  return prev;
}

export function useImagePreload(...imagePaths: string[]) {
  useEffect(() => {
    imagePaths.forEach((imagePath) => {
      //Create a new imageobject in memory with source set to force image load
      new Image().src = imagePath;
    });
  }, [...imagePaths]);
}
