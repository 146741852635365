//Wrappers to support language mostly
import { ComponentProps, useCallback } from 'react';
import {
  Link as LinkCore,
  NavigateOptions,
  To,
  useLocation,
  useNavigate as useNavigateCore,
  useParams,
} from 'react-router-dom';

export const langRegex = /^\/([a-zA-Z]{2})\//;

function localizeAbsolutePath(to: To, currentPath: string) {
  if (typeof to === 'string' && to.startsWith('/')) {
    const lang = currentPath?.match(langRegex)?.[1];
    if (lang && !langRegex.test(to)) {
      return `/${lang}${to}`;
    }
  }
  return to;
}

/** Wrapper around useNavigate to support persisting language prefixes on navigation (ie: /de/*) */
export function useNavigate() {
  const navigateCore = useNavigateCore();
  const currentPath = useLocation().pathname;
  return useCallback(
    (to: To, options?: NavigateOptions) => {
      to = localizeAbsolutePath(to, currentPath);
      navigateCore(to, options);
    },
    [currentPath, navigateCore],
  );
}

export function Link({ to, ...props }: ComponentProps<typeof LinkCore>) {
  const currentPath = useLocation().pathname;
  return <LinkCore to={localizeAbsolutePath(to, currentPath)} {...props} />;
}

export function useParamsRequired<T extends string[]>(...paramKeys: T) {
  const values = useParams();
  const pathname = useLocation().pathname;
  return paramKeys.reduce(
    (acc, key) => {
      const value = values[key];
      if (!value) {
        throw new Error(
          `"${key}" is missing in params but required on path "${pathname}"`,
        );
      }
      //@ts-expect-error: I know what I'm doing
      acc[key] = value;
      return acc;
    },
    {} as { [P in T[number]]: string },
  );
}
