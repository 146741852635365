import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { checkWebPayment } from '@/actions/payment/check-web-payment';
import { useTranslation } from '@/i18n/client';
import { useNavigate, useParamsRequired } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

const MAX_CHECKS_BEFORE_FAILURE = 5;

export function CheckoutPendingPage() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const { restaurantId } = useParamsRequired('restaurantId');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const restaurant = useMainStore((state) => state.restaurant);

  const [paymentCheckCount, setPaymentCheckCount] = useState(0);

  useEffect(() => {
    if (paymentCheckCount >= MAX_CHECKS_BEFORE_FAILURE) {
      toast.error(t('Toast - Payment failed', 'Payment failed'));
      navigate(`/${restaurantId}/checkout/failure?orderId=${orderId}`);
    }
    const load = async () => {
      if (!orderId) {
        toast.error(t('Toast - Order not found', 'Your order was not found'));
        return;
      }
      const responseStatus = await checkWebPayment({ orderId });

      if (!responseStatus) {
        throw new Error('Could not get payment status');
      }

      if (responseStatus.status === 'PENDING') {
        return setPaymentCheckCount((count) => count + 1);
      }

      if (responseStatus.status === 'ABORTED') {
        toast.error(
          responseStatus.message ||
            t('Toast - Payment failed', 'Payment aborted'),
        );
        navigate(`/${restaurantId}/checkout`);
      } else if (responseStatus.status === 'FAILED') {
        toast.error(
          responseStatus.message ||
            t('Toast - Payment failed', 'Payment failed'),
        );
        navigate(`/${restaurantId}/checkout/failure?orderId=${orderId}`);
      } else if (
        responseStatus.status === 'AUTHORIZED' ||
        responseStatus.status === 'CAPTURED'
      ) {
        toast.success(t('Toast - Payment successful', 'Payment successful'));
        navigate(`/${restaurantId}/checkout/success?orderId=${orderId}`);
      }
    };

    //Wait 500ms first poll, then 1500ms for subsequent queries.
    const timeout = setTimeout(load, paymentCheckCount === 0 ? 500 : 1500);
    return () => clearTimeout(timeout);
  }, [paymentCheckCount]);

  if (!restaurant) {
    return null;
  }

  return (
    <div className="flex h-[100svh] flex-col items-center justify-center gap-3 px-5">
      <p className="text-center text-xl font-semibold">
        {t(
          'Checkout - Pending - Processing payment',
          'We are processing your payment, please wait a moment...',
        )}
      </p>
    </div>
  );
}
