import { X } from 'lucide-react';
import {
  CSSProperties,
  ChangeEvent,
  ComponentProps,
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerOverlay,
  DrawerTitle,
} from '@/components/ui/drawer';
import { Input } from '@/components/ui/input';
import { rappenToFrancs } from '@/lib/utils';

const MAX_TIPPING_AMOUNT_RAPPEN = 500_00;
const USE_PERCENTAGES_FROM_RAPPEN = 10_00;

type AmountSelection = { amountRappen: number; label: string };

function tippingAmountsPercentage(orderAmountRappen: number) {
  const getRoundedPercentage = (percent: number) => {
    const percentageValue = Math.round(orderAmountRappen * (percent * 0.01));
    return percentageValue - (percentageValue % 5);
  };
  return [
    {
      label: '0%',
      amountRappen: 0,
    },
    {
      label: '5%',
      amountRappen: getRoundedPercentage(5),
    },
    {
      label: '10%',
      amountRappen: getRoundedPercentage(10),
    },
    {
      label: '15%',
      amountRappen: getRoundedPercentage(15),
    },
  ];
}

const tippingAmountsFixed: AmountSelection[] = [
  { label: '0.00', amountRappen: 0 },
  { label: '0.50', amountRappen: 50 },
  { label: '1.00', amountRappen: 100 },
  { label: '2.00', amountRappen: 200 },
];

type Props = {
  open: boolean;
  orderAmountRappen: number;
  onSelection(amountRappen: number): void;
  onCancel(): void;
};

export function Tipping(props: Props) {
  const { t } = useTranslation();

  const amounts = useMemo<AmountSelection[]>(
    () =>
      props.orderAmountRappen >= USE_PERCENTAGES_FROM_RAPPEN
        ? tippingAmountsPercentage(props.orderAmountRappen)
        : tippingAmountsFixed,
    [props.orderAmountRappen],
  );
  const [tipAmountRappen, setTipAmountRappen] = useState<number>();

  const [useCustomInput, setUseCustomInput] = useState(false);

  const [customInputValue, setCustomInputValue] = useState('');

  const toggleCustomInput = useCallback(() => {
    setUseCustomInput((val) => !val);
    setTipAmountRappen(undefined);
    setCustomInputValue('');
  }, []);

  const updateCustomAmount = useCallback(
    (ev: ChangeEvent<HTMLInputElement>) => {
      const value = ev.currentTarget.value;
      const chfValue = parseFloat(value);
      const isValid = chfValue != null && !isNaN(chfValue);
      if (chfValue * 100 > MAX_TIPPING_AMOUNT_RAPPEN) {
        return;
      }
      setTipAmountRappen(isValid ? Math.round(chfValue * 100) : undefined);
      setCustomInputValue(value);
    },
    [],
  );

  const onSubmit = useCallback(
    (ev: FormEvent<HTMLFormElement>) => {
      ev.preventDefault();
      if (tipAmountRappen != null) props.onSelection(tipAmountRappen);
    },
    [tipAmountRappen, props.onSelection],
  );

  return (
    <Drawer open={props.open} onClose={props.onCancel} disablePreventScroll>
      <DrawerOverlay onClick={props.onCancel} />
      <DrawerContent aria-describedby={undefined} disableOverlay>
        <form onSubmit={onSubmit} className="my-5 flex flex-col gap-5 px-5">
          <DrawerTitle className="text-xl font-bold">
            {t('Tipping - Heading', { defaultValue: 'Select tip for order' })}
          </DrawerTitle>
          <div className="flex items-center gap-1.5">
            {useCustomInput ? (
              <>
                <Input
                  value={customInputValue}
                  type="number"
                  inputMode="decimal"
                  onChange={updateCustomAmount}
                  autoFocus
                  required
                />
                <Button
                  key={'close'}
                  className="px-3"
                  type="button"
                  variant={'outline'}
                  onClick={toggleCustomInput}
                >
                  <X size={20} />
                </Button>
              </>
            ) : (
              <>
                {amounts.map((amount, index) => {
                  const isSelected = tipAmountRappen === amount.amountRappen;
                  return (
                    <Button
                      key={index}
                      type="button"
                      className="relative h-12 flex-1 px-1 transition-transform active:scale-105"
                      variant={isSelected ? 'default' : 'outline'}
                      onClick={() => {
                        setTipAmountRappen(amount.amountRappen);
                      }}
                    >
                      {amount.label}
                      {isSelected &&
                        new Array(Math.round(index * 1.5))
                          .fill(null)
                          .map((_, index) => <TippingHeart key={index} />)}
                    </Button>
                  );
                })}
                <Button
                  variant="outline"
                  className="h-12 px-3 text-sm"
                  onClick={toggleCustomInput}
                >
                  ...
                </Button>
              </>
            )}
          </div>
          <p className="pl-0.5 text-muted-foreground">
            {tipAmountRappen != null
              ? tipAmountRappen == 0
                ? t('Tipping - Confirm no tip', {
                    defaultValue: 'Continue without tip',
                  })
                : t('Tipping - Confirm selected amount', {
                    defaultValue: 'Add tip of {{amount}} CHF',
                    amount: rappenToFrancs(tipAmountRappen),
                  })
              : t('Tipping - Select amount', {
                  defaultValue: 'Select tipping amount',
                })}
          </p>
          <Button
            type="submit"
            className="h-12"
            disabled={tipAmountRappen == null}
          >
            {t('Tipping - Checkout total price', {
              defaultValue: 'Order now - {{total}} CHF',
              total: rappenToFrancs(
                props.orderAmountRappen + (tipAmountRappen ?? 0),
              ),
            })}
          </Button>
        </form>
      </DrawerContent>
    </Drawer>
  );
}

function TippingHeart() {
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    setHasRendered(true);
  }, []);
  const transitionStyle: CSSProperties = useMemo(() => {
    return {
      translate: `${-250 + Math.random() * 500}% -${100 + Math.random() * 100}%`,
      rotate: `${-140 + Math.random() * 280}deg`,
      opacity: 0,
      fill: ['#AF272F', '#C06065', '#CB8589', '#DDBEC0'][
        Math.floor(Math.random() * 4)
      ],
      //We want opacity on an aggressive ease-in to delay fade out effect, so it needs to be declared here as well.
      transition:
        'translate 1100ms, rotate 1100ms, opacity 1100ms cubic-bezier(1, 0, 0.7, 0)',
    };
  }, []);
  return (
    <HeartSvg
      className={'absolute top-0 h-5 w-5 origin-center'}
      style={hasRendered ? transitionStyle : {}}
    />
  );
}

function HeartSvg(props: ComponentProps<'svg'>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 130 130">
      <path
        fill={props.style?.fill}
        d="M 65,29 C 59,19 49,12 37,12 20,12 7,25 7,42 7,75 25,80 65,118 105,80 123,75 123,42 123,25 110,12 93,12 81,12 71,19 65,29 z"
      />
    </svg>
  );
}
