import { ArrowDownToLine } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';

import { getCart } from '@/actions/cart/get-cart';
import { getOrder } from '@/actions/order/get-order';
import { Receipt } from '@/components/common/receipt/receipt';
import { buttonVariants } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { Eat_Order_Table } from '@/lib/__generated__/graphql';
import { useNavigate } from '@/lib/navigation';
import { cn } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

export function CheckoutSuccessPage() {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get('orderId');

  const { restaurantId } = useParams();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const restaurant = useMainStore((state) => state.restaurant);
  const { setCart, setComments } = useMainStore();

  const [order, setOrder] = useState<Eat_Order_Table | null>(null);

  const html2CanvasImport = useMemo(() => import('html2canvas'), []);

  useEffect(() => {
    let maxTryInterval = 20;
    const interval = setInterval(async () => {
      if (!orderId || maxTryInterval <= 0) {
        toast.error(t('Toast - Order not found', 'Your order was not found'));
        clearInterval(interval);
        navigate(`/${restaurantId}/checkout/failure?callbackId=${orderId}`);
        return;
      }

      const fetchedOrder = await getOrder({ orderId: orderId });

      if (!isOrderSuccess(fetchedOrder)) {
        maxTryInterval = maxTryInterval - 1;
        return;
      }

      setOrder(fetchedOrder);

      // Cart should have been cleared by now. Fetch it again.
      const cart = await getCart({ restaurantId: restaurantId! });
      setCart(cart);

      // Clear comments, should only be used for the current order.
      setComments('');

      if (fetchedOrder) {
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const receiptRef = useRef<HTMLDivElement>(null);

  const handleDownload = async () => {
    const receipt = receiptRef.current;
    if (receipt === null) {
      return;
    }

    try {
      const canvas = await html2CanvasImport.then(({ default: html2Canvas }) =>
        html2Canvas(receipt),
      );
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'receipt.png';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Could not export image', error);
    }
  };

  if (!restaurant) {
    return null;
  }

  return (
    <div className="mt-14 flex flex-col items-center gap-3 px-5">
      <p className="text-xl font-semibold">
        {isOrderSuccess(order)
          ? t('Checkout - Success - Title Sucess', 'Thanks for your order!')
          : t('Checkout - Success - Title pending', 'Almost there...')}
      </p>

      <DownloadReceipt order={order} onDownload={handleDownload} />

      <Receipt
        receiptRef={receiptRef}
        expandReceipt={order !== null}
        restaurantName={order?.restaurantName || restaurant.name}
        orderNumber={order?.orderNumber}
        tableNumber={order?.tableNumber}
        nameOnReceipt={order?.nameOnReceipt}
        createdAt={order?.createdAt}
        items={
          order?.items.map((item) => ({
            name: item.name,
            priceRappen: item.priceRappen * item.quantity,
            quantity: item.quantity,
          })) || []
        }
        totalPriceRappen={order?.itemsTotalRappen ?? 0}
        points={0}
      />

      <Link
        to={`/${restaurantId}`}
        className={cn(buttonVariants(), 'bg-laaxred hover:bg-laaxreddark')}
      >
        {t('Checkout - Success - Button back to menu', 'Back to the start')}
      </Link>
    </div>
  );
}

function DownloadReceipt(props: {
  order: Eat_Order_Table | null;
  onDownload: () => void;
}) {
  const [fakeProgress, setFakeProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setFakeProgress((prev) => {
        if (prev < 3) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 1000);
  }, []);

  let canDownload = false;

  // Initial loader progress with fake progress up to 3/12. Is overriden if order is pending or success.
  // Note: Can not be done with template string because of the way tailwindcss works.
  let loaderProgress = ['w-0', 'w-1/12', 'w-2/12', 'w-3/12'][fakeProgress];

  if (isOrderPending(props.order)) {
    loaderProgress = 'w-1/2';
  }

  if (isOrderSuccess(props.order)) {
    loaderProgress = 'w-full';
    canDownload = true;
  }

  return (
    <button
      className={cn(
        'relative flex w-full items-center justify-between overflow-hidden rounded-xl bg-muted p-3 text-sm',
      )}
      onClick={props.onDownload}
      disabled={!canDownload}
    >
      <div className="z-20 flex flex-col">
        <p className="font-semibold">
          {canDownload ? 'Download your receipt here' : 'Receipt'}
        </p>

        <p className="text-xs text-muted-foreground">PDF</p>
      </div>

      <ArrowDownToLine
        className={cn(
          'z-20 size-8 rounded-full p-2 transition-all duration-500',
          canDownload ? 'bg-emerald-500 text-white' : 'bg-white',
        )}
      />

      <div
        className={cn(
          'absolute inset-0 z-10 bg-emerald-100 transition-all duration-1000',
          loaderProgress,
        )}
      />
    </button>
  );
}

function isOrderSuccess(order: Eat_Order_Table | null) {
  if (!order) {
    return false;
  }
  return (
    order.tableOrderStatus === 'ORDER_DONE' ||
    order.tableOrderStatus === 'ORDER_IN_PREPARATION'
  );
}

function isOrderPending(order: Eat_Order_Table | null) {
  if (!order) {
    return false;
  }
  return order.tableOrderStatus === 'ORDER_PENDING';
}
