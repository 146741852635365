import { ReactNode, useMemo } from 'react';

import { useDelayedValue } from '@/lib/hooks';
import { cn } from '@/lib/utils';

export function ContentSlider({
  duration = 300,
  ...props
}: {
  children: ReactNode;
  contentIndex: number;
  axis: 'vertical' | 'horizontal';
  duration?: number;
}) {
  const value = useMemo(
    () => ({
      oldChildren: props.children,
      oldContentIndex: props.contentIndex,
    }),
    [props.children, props.contentIndex, props.axis],
  );
  const { oldChildren, oldContentIndex } = useDelayedValue(value, duration);
  const screenDiff = props.contentIndex - oldContentIndex;
  const isVertical = props.axis === 'vertical';
  return (
    <div className={cn('no-scrollbar relative h-full w-full overflow-hidden')}>
      <div
        style={{ animationDuration: `${duration}ms` }}
        className={cn(
          'no-scrollbar',
          screenDiff > 0 &&
            (isVertical ? 'animate-slide-in-top' : 'animate-slide-in-right'),
          screenDiff < 0 &&
            (isVertical ? 'animate-slide-in-bottom' : 'animate-slide-in-left'),
        )}
      >
        {props.children}
      </div>
      {!!screenDiff && (
        <div
          style={{ animationDuration: `${duration}ms` }}
          className={cn(
            'absolute inset-0 fill-mode-forwards',
            screenDiff > 0 &&
              (isVertical ? 'animate-slide-out-top' : 'animate-slide-out-left'),
            screenDiff < 0 &&
              (isVertical
                ? 'animate-slide-out-bottom'
                : 'animate-slide-out-right'),
          )}
        >
          {oldChildren}
        </div>
      )}
    </div>
  );
}
