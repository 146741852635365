import { RestaurantRedirect } from './components/restaurant-redirect';

export function StartPage() {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-2">
      <p className="text-2xl font-semibold">Welcome to LAAX Eat</p>

      <RestaurantRedirect
        restaurants={[
          { restaurantId: 'cafe-piazza', restaurantName: 'Piazza' },
          { restaurantId: 'camino-ma', restaurantName: 'Camino MA' },
        ]}
      />
    </div>
  );
}
