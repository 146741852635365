import { t } from 'i18next';
import { useState } from 'react';
import Markdown from 'react-markdown';

import { addItemInCart } from '@/actions/cart/add-item';
import {
  ModifierSingleSelector,
  SelectedModifiers,
} from '@/app/shared-components/modifier-single-selector';
import { useDrawerContext } from '@/components/common/drawer/drawer-layout';
import { DrawerNavigationHeader } from '@/components/common/drawer/drawer-nav-header';
import { Button } from '@/components/ui/button';
import { DrawerContent, DrawerFooter } from '@/components/ui/drawer';
import { Eat_Menu_Item } from '@/lib/__generated__/graphql';
import { rappenToFrancs, wait } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

type Props = {
  restaurantId: string;
  menuItem: Eat_Menu_Item;
};

export function FocusMenuItem(props: Props) {
  const setCart = useMainStore((state) => state.setCart);

  const drawer = useDrawerContext();

  const [selectedModifiers, setSelectedModifiers] = useState<
    SelectedModifiers[]
  >([]);

  const modifiersSelected =
    selectedModifiers.length === (props.menuItem.modifierGroups?.length ?? 0);

  const [currentlyAddingItem, setCurrentlyAddingItem] = useState(false);

  return (
    <DrawerContent className="overflow-hidden" disableOverlay>
      <DrawerNavigationHeader title="" close />
      <div className="mx-auto">
        {props.menuItem.imageUrl ? (
          <img
            src={props.menuItem.imageUrl || ''}
            alt={props.menuItem.name}
            className="h-36 w-36 rounded-md object-cover"
          />
        ) : (
          <div className="h-36 w-36 rounded-md bg-muted" />
        )}
      </div>
      <div className="flex-col gap-1 p-4">
        <div className="flex justify-between">
          <h1 className="text-lg font-bold">{props.menuItem.name}</h1>
          <p>
            {props.menuItem.priceRappen
              ? rappenToFrancs(props.menuItem.priceRappen) + ' CHF'
              : '— CHF'}
          </p>
        </div>
        <div className="text-muted-foreground">
          <Markdown>{props.menuItem.description}</Markdown>
        </div>
        <div className="mt-8">
          <ModifierSingleSelector
            modifierGroups={props.menuItem.modifierGroups}
            onModifiersSelected={setSelectedModifiers}
            selectedModifiers={selectedModifiers}
          />
        </div>
      </div>

      <DrawerFooter>
        <Button
          disabled={!modifiersSelected}
          variant={modifiersSelected ? 'default' : 'outline'}
          className="flex h-12 w-full gap-2 transition-transform duration-100 active:scale-105"
          onClick={async () => {
            if (!props.menuItem || currentlyAddingItem) return;

            setCurrentlyAddingItem(true);

            wait(50).then(() => drawer.close());

            const newCart = await addItemInCart({
              quantity: 1,
              restaurantId: props.restaurantId,
              itemSku: props.menuItem.sku,
              modifierGroups: selectedModifiers,
            });

            setCart(newCart);
          }}
        >
          <img
            className="h-5 w-5"
            src="/icons/cart.svg"
            style={{ filter: 'invert(1)' }}
          />
          {t('Menu - Item drawer - Button add cart', 'Add to your cart')}
        </Button>
      </DrawerFooter>
    </DrawerContent>
  );
}
