import { useAutoAnimate } from '@formkit/auto-animate/react';
import { ChevronLeft, CircleAlert } from 'lucide-react';

import { MenuWarningBanner } from '@/app/2-menu/components/menu-warning-banner';
import { FadeFromBelow } from '@/components/common/fade-from-below';
import { OrderFooter } from '@/components/common/order-footer';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { useImagePreload } from '@/lib/hooks';
import { Link, useNavigate } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

import { CartListItem } from './cart-list-item';

export function CartList(props: { restaurantId: string }) {
  useImagePreload('/icons/empty-cart.svg');

  const { t } = useTranslation();

  const { menu, restaurant } = useMainStore((state) => ({
    cart: state.cart,
    menu: state.menu,
    restaurant: state.restaurant,
  }));

  const [cart] = useMainStore((state) => [state.cart, state.setCart]);

  const [animationContainerRef] = useAutoAnimate({ duration: 200 });

  const navigate = useNavigate();

  if (menu === null || cart === null || restaurant === null) {
    return null;
  }

  return (
    <>
      <div className="mt-14 flex flex-col gap-5 px-5">
        <MenuWarningBanner
          className="mb-5"
          waitingTimeMinutes={restaurant.waitingTimeMinutes}
        />
        {!restaurant.orderingEnabled && (
          <div className="flex items-center gap-2 rounded-lg bg-red-100 p-3 text-red-800">
            <CircleAlert className="-mt-0.5" />{' '}
            {t('Ordering disabled', 'Restaurant is not accepting orders')}
          </div>
        )}
        <div ref={animationContainerRef}>
          {cart.entries.map((cartEntry) => {
            return (
              <CartListItem
                key={cartEntry.id}
                restaurantId={props.restaurantId}
                cartEntry={cartEntry}
                menu={menu}
              />
            );
          })}
        </div>

        {cart.entries.length === 0 && (
          <EmptyCart restaurantId={props.restaurantId} />
        )}
      </div>
      <OrderFooter
        buttonDisabled={
          !restaurant.orderingEnabled || cart.entries.length === 0
        }
        buttonText={t('Cart summary - Button - At cart page', 'Continue')}
        onButtonClick={() => navigate(`/${props.restaurantId}/details`)}
        stepNumber={2}
      />
    </>
  );
}

function EmptyCart(props: { restaurantId: string }) {
  const { t } = useTranslation();
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-4">
      <img
        src="/icons/empty-cart.svg"
        className="my-6 object-contain"
        style={{ imageRendering: 'crisp-edges' }}
        alt=""
      />
      <FadeFromBelow delay={30}>
        <h3 className="text-2xl font-bold">
          {t('Cart - Title cart empty', 'Your cart is empty')}
        </h3>
      </FadeFromBelow>
      <FadeFromBelow delay={60}>
        <p className="text-center text-muted-foreground">
          {t(
            'Cart - Text cart empty',
            'Have a look at our menu to see what you fancy and add it to the cart.',
          )}
        </p>
      </FadeFromBelow>
      <FadeFromBelow delay={90}>
        <Button asChild variant="ghost" className="items-center">
          <Link to={`/${props.restaurantId}/menu`} replace>
            <ChevronLeft className="-ml-3 -mt-[1.5px] h-5" />
            {t('Cart - Button cart empty', 'Back to menu')}
          </Link>
        </Button>
      </FadeFromBelow>
    </div>
  );
}
