import { ReactNode } from 'react';

export function FadeFromBelow({
  delay = 0,
  children,
}: {
  delay?: number;
  children: ReactNode;
}) {
  return (
    <div
      className="animate-fade-in-from-below fill-mode-both"
      style={{ animationDelay: `${delay}ms` }}
    >
      {children}
    </div>
  );
}
