import { Clock9, ExternalLink, User, Utensils } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';

import { createTableOrder } from '@/actions/order/create-table-order';
import { startWebPayment } from '@/actions/payment/start-web-payment';
import { TableIcon } from '@/components/common/icons/table-icon';
import { OrderFooter } from '@/components/common/order-footer';
import { Drawer, DrawerContent, DrawerTrigger } from '@/components/ui/drawer';
import { RadioGroup } from '@/components/ui/radio-group';
import { Separator } from '@/components/ui/separator';
import { useTranslation } from '@/i18n/client';
import {
  Eat_CartFragment,
  Eat_RestaurantFragment,
  SupportedPaymentType,
} from '@/lib/__generated__/graphql';
import { useParamsRequired } from '@/lib/navigation';
import { getAnonId } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';
import { useSelectedPayment } from '@/stores/payment-store';
import { paymentsConfig } from '@/types/types';

import { FullScreenLoader } from '../shared-components/full-screen-loader';
import { CartEntry } from './components/cart-entry-checkout';
import { PaymentSelectItem } from './components/payment-select-item';
import { Tipping } from './components/tipping';

export function CheckoutStartPage() {
  const { restaurantId } = useParamsRequired('restaurantId');

  if (!restaurantId) {
    throw new Error('No restaurantId in path');
  }

  const { t } = useTranslation();

  const { cart, restaurant, nameOnReceipt, commentOnReceipt, tableNumber } =
    useMainStore((state) => ({
      cart: state.cart,
      menu: state.menu,
      restaurant: state.restaurant,
      nameOnReceipt: state.nameOnReceipt,
      commentOnReceipt: state.comments,
      tableNumber: state.tableNumber,
    }));

  const { selectedPayment } = useSelectedPayment();

  const [isTipping, setIsTipping] = useState(false);

  const [isLoadingStartWebCheckout, setIsLoadingStartWebCheckout] =
    useState(false);

  const startCheckout = async (tipRappen: number) => {
    if (!tableNumber) {
      // FIXME: Currently not possible to set this at the checkout
      // FIXME: This is specific to TABLE dining mode
      toast.error(
        t('Toast - Table number required', 'A table number is required'),
      );
      return;
    }

    if (!cart) {
      toast.error(t('Toast - Cart not found', 'Cart not found'));
      return;
    }

    if (!selectedPayment) {
      toast.error(
        t('Toast - No payment method selected', 'No payment method selected'),
      );
      return;
    }

    setIsLoadingStartWebCheckout(true);
    const order = await createTableOrder({
      anonId: getAnonId(),
      input: {
        entriesTotalRappen: cart.entriesTotalRappen,
        nameOnReceipt,
        commentOnReceipt,
        restaurantId,
        tableNumber,
        tipRappen,
      },
    }).catch((err) => {
      toast.error(err.message);
    });

    if (!order) {
      setIsLoadingStartWebCheckout(false);

      return;
    }

    const { paymentPageUrl } = await startWebPayment({
      input: {
        anonId: getAnonId(),
        orderId: order.id,
        paymentType: selectedPayment.id,
        returnUrl: `${origin}/${restaurantId}/checkout/pending?orderId=${order.id}`,
      },
    });

    if (!paymentPageUrl) {
      toast.error('Failed to start checkout');
      setIsLoadingStartWebCheckout(false);
      return;
    }

    window.location.href = paymentPageUrl;
  };

  if (cart === null || restaurant === null) {
    return null;
  }

  return (
    <>
      <main className="mt-14 flex flex-col divide-y-2 divide-muted">
        <OrderDetailsSection
          restaurant={restaurant}
          tableNumber={tableNumber}
          nameOnReceipt={nameOnReceipt}
        />
        <WaitingTimeSection restaurant={restaurant} />
        <OrderItemsSection cart={cart} />
        <PaymentSection />
        <TermsAndConditionsSection />
      </main>

      <Tipping
        open={isTipping}
        orderAmountRappen={cart.entriesTotalRappen}
        onCancel={() => setIsTipping(false)}
        onSelection={(tipRappen) => {
          setIsTipping(false);
          startCheckout(tipRappen);
        }}
      />

      {isLoadingStartWebCheckout && <FullScreenLoader />}

      <OrderFooter
        stepNumber={4}
        buttonText={t('Cart summary - Button - At details page', 'To checkout')}
        onButtonClick={() => setIsTipping(true)}
        buttonDisabled={isLoadingStartWebCheckout || !selectedPayment}
      />
    </>
  );
}

function OrderDetailsSection(props: {
  restaurant: Eat_RestaurantFragment;
  tableNumber: string | null;
  nameOnReceipt: string;
}) {
  const { t } = useTranslation();

  return (
    <div className="mx-5 my-2 flex flex-col divide-y-1 divide-muted">
      <div className="flex flex-row items-center py-2.5">
        <div className="w-12">
          <Utensils className="size-5" />
        </div>
        <p className="text-md font-medium">{props.restaurant.name}</p>
      </div>

      <div className="flex flex-row items-center py-2.5">
        <div className="-ml-0.5 w-12">
          <TableIcon />
        </div>
        <p className="text-md font-medium">
          {t('Checkout - Table', 'Table')} {props.tableNumber}
        </p>
      </div>

      <div className="flex flex-row items-center py-2.5">
        <div className="w-12">
          <User className="size-5" />
        </div>
        <p className="text-md font-medium">{props.nameOnReceipt}</p>
      </div>
    </div>
  );
}

function WaitingTimeSection(props: { restaurant: Eat_RestaurantFragment }) {
  const { t } = useTranslation();

  // FIXME: Hard-coded waiting time threshold
  if (props.restaurant.waitingTimeMinutes < 20) {
    return null;
  }

  return (
    <div className="flex items-center gap-3 px-5 py-5">
      <Clock9 className="h-6 w-6 fill-yellow-400 text-white" />
      <p className="text-sm font-semibold">
        {t(
          'Checkout - Expected waiting time',
          'Expected waiting time {{waitingTimeMinutes}} min',
          { waitingTimeMinutes: props.restaurant.waitingTimeMinutes },
        )}
      </p>
    </div>
  );
}

function OrderItemsSection(props: { cart: Eat_CartFragment }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col gap-3 px-5 pb-5 pt-4">
      <h1 className="text-xl font-bold">
        {t('Checkout - Order summary section title', 'Your items')}
      </h1>

      {props.cart.entries.map((cartEntry) => (
        <CartEntry key={cartEntry.id} cartEntry={cartEntry} />
      ))}
    </div>
  );
}

function PaymentSection() {
  const { t } = useTranslation();

  const { selectedPayment, setSelectedPaymentType } = useSelectedPayment();

  const [isPaymentDrawerOpen, setIsPaymentDrawerOpen] = useState(false);

  return (
    <div className="flex flex-col gap-3 px-5 pb-5 pt-3">
      <h1 className="text-xl font-bold">
        {t('Checkout - Payment', 'Payment')}
      </h1>

      <Drawer open={isPaymentDrawerOpen} onOpenChange={setIsPaymentDrawerOpen}>
        <DrawerTrigger asChild>
          <div className="rounded-md border p-3">
            <div className="flex w-full items-center justify-between hover:cursor-pointer">
              {selectedPayment ? (
                <div className="flex flex-1 items-center py-1">
                  <div className="flex flex-1 items-center gap-3">
                    <img
                      src={selectedPayment.imageUrl}
                      alt={selectedPayment.name}
                      className="h-6"
                    />
                    <p className="text-md shrink-0">{selectedPayment?.name}</p>
                  </div>
                  <p className="text-sm text-blue-600">
                    {t('Checkout - Change payment', 'change')}
                  </p>
                </div>
              ) : (
                <p className="text-md text-blue-600">
                  {t(
                    'Checkout - Select payment method',
                    'Select payment method',
                  )}
                </p>
              )}
            </div>

            <Separator className="my-2 h-[1px] bg-muted" />

            <p className="text-sm">
              {t(
                'Checkout - Information redirection to saferpay',
                'On checkout, you’ll be redirected to a secure payment page to enter your payment details.',
              )}
            </p>
          </div>
        </DrawerTrigger>

        <DrawerContent className="px-3 pb-2">
          <RadioGroup
            className="flex flex-col gap-0 divide-y-1 divide-muted pt-2"
            value={selectedPayment?.id}
            onValueChange={(value) => {
              setSelectedPaymentType(value as SupportedPaymentType);
              setIsPaymentDrawerOpen(false);
            }}
          >
            {paymentsConfig.map((paymentConfig) => (
              <PaymentSelectItem
                key={paymentConfig.id}
                paymentConfig={paymentConfig}
                setSelectedPaymentType={setSelectedPaymentType}
                setIsPaymentDrawerOpen={setIsPaymentDrawerOpen}
              />
            ))}
          </RadioGroup>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

function TermsAndConditionsSection() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center py-5">
      <p className="max-w-xs text-center text-sm">
        <a
          // FIXME: Link to the actual terms & conditions
          href="https://laax.com"
          target="_blank"
          className="text-blue-700"
        >
          {t(
            'Checkout - Terms and conditions 1',
            'By continuing, you agree to the',
          )}
          <br />
          {t('Checkout - Terms and conditions 2', 'Terms & Conditions')}
        </a>
        <ExternalLink className="-mt-0.5 ml-1 inline size-3 text-blue-700" />
      </p>
    </div>
  );
}
