import { ChangeEvent, ForwardedRef, forwardRef } from 'react';

import { FadeFromBelow } from '@/components/common/fade-from-below';
import { RadioButton } from '@/components/common/radio-button';
import { Eat_Menu_Item } from '@/lib/__generated__/graphql';

export type SelectedModifiers = {
  id: string;
  name: string;
  modifiers: {
    id: string;
    name: string;
  }[];
};

export const ModifierSingleSelector = forwardRef(
  function ItemModifierRadioButtons(
    props: {
      modifierGroups: Eat_Menu_Item['modifierGroups'];
      selectedModifiers: SelectedModifiers[];
      onModifiersSelected(selectedModifiers: SelectedModifiers[]): void;
    },
    ref: ForwardedRef<HTMLDivElement | null>,
  ) {
    const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
      const currentTarget = ev.currentTarget;
      if (!currentTarget.checked) {
        return;
      }

      const selectedModifiers = structuredClone(props.selectedModifiers);

      const modifierGroupId = ev.currentTarget.name;
      const modifierId = ev.target.value;

      const selectedModifierGroup = selectedModifiers.find(
        (group) => group.id === modifierGroupId,
      );
      const menuModifierGroup = props.modifierGroups?.find(
        (group) => group.id === modifierGroupId,
      );
      const menuModifier = menuModifierGroup?.modifiers.find(
        (modifier) => modifier.id === modifierId,
      );

      if (!menuModifier || !menuModifierGroup) {
        return;
      }

      if (selectedModifierGroup) {
        selectedModifierGroup.modifiers = [
          {
            id: menuModifier.id,
            name: menuModifier.name,
          },
        ];
      } else {
        selectedModifiers.push({
          id: modifierGroupId,
          name: menuModifierGroup.name,
          modifiers: [
            {
              id: menuModifier.id,
              name: menuModifier.name,
            },
          ],
        });
      }
      props.onModifiersSelected(selectedModifiers);
    };

    const isChecked = (modifierGroupId: string, modifierId: string) => {
      const modifierGroup = props.selectedModifiers.find(
        (group) => group.id === modifierGroupId,
      );
      return modifierGroup
        ? modifierGroup.modifiers.some((mod) => mod.id === modifierId)
        : false;
    };

    if (!props.modifierGroups || props.modifierGroups.length === 0) {
      return null;
    }

    let animationDelay = 0;

    return (
      <div className="flex flex-col gap-6 pb-3" ref={ref}>
        {props.modifierGroups.map((modifierGroup) => {
          animationDelay += 30;
          return (
            <div className="flex flex-col gap-4" key={modifierGroup.id}>
              <FadeFromBelow delay={animationDelay}>
                <h3
                  className="leading-4"
                  style={{ animationDelay: `${animationDelay}ms` }}
                >
                  {modifierGroup.name}
                </h3>
              </FadeFromBelow>
              {modifierGroup.modifiers.map((modifier) => {
                animationDelay += 30;
                return (
                  <FadeFromBelow key={modifier.id} delay={animationDelay}>
                    <RadioButton
                      key={modifier.id}
                      name={modifierGroup.id}
                      value={modifier.id}
                      checked={isChecked(modifierGroup.id, modifier.id)}
                      label={modifier.name}
                      onChange={onChange}
                    />
                  </FadeFromBelow>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  },
);
