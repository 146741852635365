import { ApolloProvider } from '@apollo/client';
import React from 'react';

import { apolloClient } from '@/lib/apollo/apollo-client';

export function ApolloWrapper(props: { children: React.ReactNode }) {
  return (
    <ApolloProvider client={apolloClient}>{props.children}</ApolloProvider>
  );
}
