import { X } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { OrdersList } from '@/app/shared-components/orders-list';
import { TableIcon } from '@/components/common/icons/table-icon';
import { Receipt } from '@/components/common/receipt/receipt';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import {
  Eat_Order_Table,
  Eat_RestaurantFragment,
} from '@/lib/__generated__/graphql';
import { useMainStore } from '@/stores/main-store';

import { ViewMenuButton } from './view-menu-button';

type Props = {
  restaurant: Eat_RestaurantFragment;
  tableNumber: string | null;
  orders: Eat_Order_Table[];
};

export function YourOrders(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { restaurant, tableNumber } = useMainStore((state) => ({
    restaurant: state.restaurant,
    tableNumber: state.tableNumber,
  }));

  const [selectedOrder, setSelectedOrder] = useState<Eat_Order_Table>();

  if (!restaurant) {
    return null;
  }

  if (selectedOrder) {
    return (
      <div className="relative flex flex-col items-center">
        <button
          className="absolute right-0 top-0 px-1.5 py-3"
          onClick={() => {
            setSelectedOrder(undefined);
          }}
        >
          <X strokeWidth={1.75} />
        </button>

        <Receipt
          expandReceipt={true}
          restaurantName={selectedOrder.restaurantName}
          tableNumber={selectedOrder.tableNumber}
          orderNumber={selectedOrder.orderNumber}
          nameOnReceipt={selectedOrder.nameOnReceipt}
          createdAt={selectedOrder.createdAt}
          items={selectedOrder.items}
          totalPriceRappen={selectedOrder.itemsTotalRappen}
          points={0}
        />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="flex flex-col items-center">
        <p className="mb-5 text-3xl font-semibold">{restaurant.name}</p>

        <div className="flex flex-col items-center gap-3">
          <div className="flex items-center gap-1">
            <TableIcon />

            <p>
              {t('Home - Table', 'Table')} {tableNumber}
            </p>
          </div>

          <div className="flex flex-col items-center gap-3 pt-3">
            <ViewMenuButton
              restaurant={props.restaurant}
              tableNumber={props.tableNumber}
            />
          </div>

          <div className="mt-5 w-full items-center">
            <div className="flex items-center justify-between gap-2">
              <h1 className="text-2xl font-semibold">
                {t('Home - Orders', 'Orders')}
              </h1>
              <Button
                variant="link"
                onClick={() => {
                  navigate(`/${props.restaurant.id}/orders`);
                }}
              >
                {t('Home - All orders button', 'All orders')}
              </Button>
            </div>

            <OrdersList
              orders={props.orders}
              onOrderSelect={setSelectedOrder}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
