import { t } from 'i18next';
import { useParams } from 'react-router-dom';

import { FocusMenuEntry } from '@/app/2-menu/components/focus-menu-entry';
import { MenuHeader } from '@/app/2-menu/components/menu-header';
import { MenuSection } from '@/app/2-menu/components/menu-section';
import { MenuSectionTabs } from '@/app/2-menu/components/menu-section-tabs';
import { MenuWarningBanner } from '@/app/2-menu/components/menu-warning-banner';
import { DrawerLayout } from '@/components/common/drawer/drawer-layout';
import { OrderFooter } from '@/components/common/order-footer';
import { useNavigate } from '@/lib/navigation';
import { useMainStore } from '@/stores/main-store';

export function MenuPage() {
  const navigate = useNavigate();

  const { sku } = useParams();

  const { restaurant, cart, menu } = useMainStore((state) => ({
    restaurant: state.restaurant,
    cart: state.cart,
    menu: state.menu,
  }));

  if (!restaurant || !menu || !cart) {
    return null;
  }

  return (
    <DrawerLayout
      drawer={sku && <FocusMenuEntry restaurantId={restaurant.id} sku={sku} />}
    >
      <div className="flex flex-col">
        <div className="sticky top-9 mt-9 flex w-full flex-col gap-5 px-5">
          <MenuHeader restaurant={restaurant} />

          <MenuWarningBanner
            waitingTimeMinutes={restaurant.waitingTimeMinutes}
          />
        </div>

        <div className="z-10 flex flex-col bg-white pt-1">
          <MenuSectionTabs sections={menu.sections} />

          <div className="flex flex-col">
            {menu.sections.map((section, index) => (
              <MenuSection
                key={section.name}
                sectionId={section.id}
                sectionName={section.name}
                entries={section.entries}
                restaurantId={restaurant.id}
                cart={cart}
                withHeader={index > 0}
              />
            ))}
            <div className="flex h-[10svh] items-center justify-center" />
          </div>
        </div>
        <OrderFooter
          buttonText={t('Cart summary - Button - At menu page', 'To the Cart')}
          buttonDisabled={cart.entries.length === 0}
          stepNumber={1}
          onButtonClick={() => navigate(`/${restaurant.id}/cart`)}
        />
      </div>
    </DrawerLayout>
  );
}
