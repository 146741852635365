import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import { SupportedPaymentType } from '@/lib/__generated__/graphql';
import { paymentsConfig } from '@/types/types';

export const useSelectedPaymentType = create<{
  selectedPaymentType: SupportedPaymentType | undefined;
  setSelectedPaymentType: (paymentType: SupportedPaymentType) => void;
}>()(
  persist(
    (set) => ({
      selectedPaymentType: undefined,
      setSelectedPaymentType: (paymentType) =>
        set(() => ({ selectedPaymentType: paymentType })),
    }),
    {
      name: 'selected-payment-type',
    },
  ),
);

export function useSelectedPayment() {
  return useSelectedPaymentType((state) => ({
    setSelectedPaymentType: state.setSelectedPaymentType,
    selectedPayment: paymentsConfig.find(
      (payment) => payment.id === state.selectedPaymentType,
    ),
  }));
}
