import { useEffect, useState } from 'react';

import { listOrdersToday } from '@/actions/order/list-orders-today';
import { Eat_Order_Table } from '@/lib/__generated__/graphql';
import { useParamsRequired } from '@/lib/navigation';
import { getAnonId } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

import { Welcome } from './components/welcome';
import { YourOrders } from './components/your-orders';

export function RestaurantPage() {
  const { restaurantId } = useParamsRequired('restaurantId');
  const [orders, setOrders] = useState<Eat_Order_Table[]>([]);

  const { restaurant, tableNumber } = useMainStore((state) => ({
    restaurant: state.restaurant,
    tableNumber: state.tableNumber,
  }));

  useEffect(() => {
    const load = async () => {
      const anonId = getAnonId();

      const todayOrders = await listOrdersToday(anonId);
      setOrders(
        todayOrders.filter((order) => order.restaurantId === restaurantId),
      );
    };

    load();
  }, []);

  if (!restaurant) {
    return null;
  }

  return (
    <div className="flex h-[100svh] w-full flex-col">
      <div className="relative h-full max-h-[55vh] w-full">
        <img
          src={restaurant.heroImageUrl}
          className="absolute inset-0 h-full w-full object-cover"
          alt=""
        />
      </div>

      <div className="z-20 -mt-10 flex h-full flex-col items-center justify-between gap-2 rounded-t-lg bg-white py-5">
        {orders.length > 0 ? (
          <YourOrders
            restaurant={restaurant}
            tableNumber={tableNumber}
            orders={orders}
          />
        ) : (
          <Welcome restaurant={restaurant} tableNumber={tableNumber} />
        )}
      </div>
    </div>
  );
}
