import { ReactNode, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getRestaurantWithMenuAndCart } from '@/actions/get-restaurant';
import { Radar } from '@/app/1-restaurant/components/radar';
import { Button } from '@/components/ui/button';
import { useTranslation } from '@/i18n/client';
import { cn } from '@/lib/utils';
import { useMainStore } from '@/stores/main-store';

type Props = {
  restaurantId: string;
  children: ReactNode;
};

export function RestaurantLoader(props: Props) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasErrored, setHasErrored] = useState(false);

  const { setRestaurant, setMenu, setCart, setTableNumber } = useMainStore(
    (state) => ({
      setRestaurant: state.setRestaurant,
      setMenu: state.setMenu,
      setCart: state.setCart,
      setTableNumber: state.setTableNumber,
    }),
  );

  async function load() {
    setHasErrored(false);

    let data;
    try {
      data = await getRestaurantWithMenuAndCart({
        restaurantId: props.restaurantId,
      });
    } catch {
      setHasErrored(true);
      return;
    }

    setRestaurant(data.restaurant);
    setMenu(data.menu);
    setCart(data.cart);

    setHasLoaded(true);
  }

  useEffect(() => {
    const tableNumber = searchParams.get('tableNumber');
    if (tableNumber) setTableNumber(tableNumber);
  }, [searchParams]);

  useEffect(() => {
    load().catch();
  }, []);

  return (
    <div className="h-full w-full">
      {hasLoaded && props.children}

      <div
        className={cn(
          'absolute bottom-0 left-0 right-0 top-0 bg-white transition-opacity duration-500',
          hasLoaded
            ? 'pointer-events-none opacity-0'
            : 'pointer-events-auto opacity-100',
        )}
      >
        <div
          className={cn(
            'absolute bottom-0 left-0 right-0 flex h-1/3 w-full flex-col items-center justify-center gap-4 p-8 transition-opacity duration-200',
            hasErrored
              ? 'pointer-events-auto opacity-100'
              : 'pointer-events-none opacity-0',
          )}
        >
          <p className="text-md text-center">
            {t(
              'Home - Something went wrong',
              'Sorry, something went wrong loading the restaurant menu. Please try again.',
            )}
          </p>
          <Button
            className="w-1/2 bg-laaxred hover:bg-laaxreddark"
            onClick={load}
          >
            {t('Home - Retry', 'Retry')}
          </Button>
        </div>

        <Radar
          style={{
            position: 'absolute',
            width: '25vw',
            height: '25vw',
            top: 'calc(33% - 25vw / 2)',
            left: 'calc(50% - 25vw / 2)',
          }}
        />
      </div>
    </div>
  );
}
