import { createRoot } from 'react-dom/client';

import { App } from './app';

const container = document.getElementById('root');
if (container == null) {
  alert("Root element #root can't be found.");
  throw "Root element #root can't be found";
}
const root = createRoot(container);
root.render(<App />);
