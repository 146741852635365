export function TableIcon(props: { className?: string }) {
  return (
    <svg
      className={props.className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_582_46087)">
        <path d="M4.5 7V17ZM1.5 7H23.5ZM4.5 10H20.5ZM20.5 7V17Z" />
        <path
          d="M4.5 7V17M1.5 7H23.5M4.5 10H20.5M20.5 7V17"
          stroke="#000"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_582_46087">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
