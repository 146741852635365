import { Eat_Cart_Entry } from '@/lib/__generated__/graphql';
import { rappenToFrancs } from '@/lib/utils';

export function CartEntry(props: { cartEntry: Eat_Cart_Entry }) {
  return (
    <div className="flex items-center justify-between">
      <p className="text-md">
        <span className="text-red-700">{props.cartEntry.quantity + '×'}</span>{' '}
        {props.cartEntry.__typename === 'Eat_Cart_Deal'
          ? props.cartEntry.menuDeal.name
          : props.cartEntry.menuItem.name}
      </p>

      <p className="text-md font-medium">
        {rappenToFrancs(props.cartEntry.priceRappen)}{' '}
        <span className="text-xs font-medium">CHF</span>
      </p>
    </div>
  );
}
